/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service.proto" (package "verily_me.web.userprofile.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { NotificationUserPreferencesService } from "./notification_user_preferences_service";
import type { BatchUpdateNotificationUserPreferencesUnauthenticatedResponse } from "./notification_user_preferences_service";
import type { BatchUpdateNotificationUserPreferencesUnauthenticatedRequest } from "./notification_user_preferences_service";
import type { ListNotificationUserPreferencesUnauthenticatedRequest } from "./notification_user_preferences_service";
import type { UnsubscribeFromEmailResponse } from "./notification_user_preferences_service";
import type { UnsubscribeFromEmailRequest } from "./notification_user_preferences_service";
import type { BatchUpdateNotificationUserPreferencesResponse } from "./notification_user_preferences_service";
import type { BatchUpdateNotificationUserPreferencesRequest } from "./notification_user_preferences_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListNotificationUserPreferencesResponse } from "./notification_user_preferences_service";
import type { ListNotificationUserPreferencesRequest } from "./notification_user_preferences_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Dedicated service for managing notification user preferences.
 *
 * @generated from protobuf service verily_me.web.userprofile.bff.api.v1.NotificationUserPreferencesService
 */
export interface INotificationUserPreferencesServiceClient {
    /**
     * Returns all notification user preferences for a participant.
     *
     * @generated from protobuf rpc: ListNotificationUserPreferences(verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesRequest) returns (verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse);
     */
    listNotificationUserPreferences(input: ListNotificationUserPreferencesRequest, options?: RpcOptions): UnaryCall<ListNotificationUserPreferencesRequest, ListNotificationUserPreferencesResponse>;
    /**
     * Sets one or more notification user preferences.
     *
     * @generated from protobuf rpc: BatchUpdateNotificationUserPreferences(verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesRequest) returns (verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesResponse);
     */
    batchUpdateNotificationUserPreferences(input: BatchUpdateNotificationUserPreferencesRequest, options?: RpcOptions): UnaryCall<BatchUpdateNotificationUserPreferencesRequest, BatchUpdateNotificationUserPreferencesResponse>;
    /**
     * Unsubscribes a user from receiving emails.
     * This is the equivalent of setting a user's email notification user preference
     * to "disabled" with BatchUpdate. Used specifically during the unauthenticated
     * unsubscribe flow.
     *
     * @generated from protobuf rpc: UnsubscribeFromEmail(verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailRequest) returns (verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailResponse);
     */
    unsubscribeFromEmail(input: UnsubscribeFromEmailRequest, options?: RpcOptions): UnaryCall<UnsubscribeFromEmailRequest, UnsubscribeFromEmailResponse>;
    /**
     * Returns all notification user preferences for a participant.
     * Accepts an unsubscribe token instead of CIAM context to validate a user's identity.
     * Used specifically during the unauthenticated unsubscribe flow.
     *
     * @generated from protobuf rpc: ListNotificationUserPreferencesUnauthenticated(verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesUnauthenticatedRequest) returns (verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse);
     */
    listNotificationUserPreferencesUnauthenticated(input: ListNotificationUserPreferencesUnauthenticatedRequest, options?: RpcOptions): UnaryCall<ListNotificationUserPreferencesUnauthenticatedRequest, ListNotificationUserPreferencesResponse>;
    /**
     * Sets one or more notification user preferences.
     * Used specifically during the unauthenticated unsubscribe flow.
     *
     * @generated from protobuf rpc: BatchUpdateNotificationUserPreferencesUnauthenticated(verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedRequest) returns (verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedResponse);
     */
    batchUpdateNotificationUserPreferencesUnauthenticated(input: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, options?: RpcOptions): UnaryCall<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, BatchUpdateNotificationUserPreferencesUnauthenticatedResponse>;
}
/**
 * Dedicated service for managing notification user preferences.
 *
 * @generated from protobuf service verily_me.web.userprofile.bff.api.v1.NotificationUserPreferencesService
 */
export class NotificationUserPreferencesServiceClient implements INotificationUserPreferencesServiceClient, ServiceInfo {
    typeName = NotificationUserPreferencesService.typeName;
    methods = NotificationUserPreferencesService.methods;
    options = NotificationUserPreferencesService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Returns all notification user preferences for a participant.
     *
     * @generated from protobuf rpc: ListNotificationUserPreferences(verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesRequest) returns (verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse);
     */
    listNotificationUserPreferences(input: ListNotificationUserPreferencesRequest, options?: RpcOptions): UnaryCall<ListNotificationUserPreferencesRequest, ListNotificationUserPreferencesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListNotificationUserPreferencesRequest, ListNotificationUserPreferencesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Sets one or more notification user preferences.
     *
     * @generated from protobuf rpc: BatchUpdateNotificationUserPreferences(verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesRequest) returns (verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesResponse);
     */
    batchUpdateNotificationUserPreferences(input: BatchUpdateNotificationUserPreferencesRequest, options?: RpcOptions): UnaryCall<BatchUpdateNotificationUserPreferencesRequest, BatchUpdateNotificationUserPreferencesResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchUpdateNotificationUserPreferencesRequest, BatchUpdateNotificationUserPreferencesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Unsubscribes a user from receiving emails.
     * This is the equivalent of setting a user's email notification user preference
     * to "disabled" with BatchUpdate. Used specifically during the unauthenticated
     * unsubscribe flow.
     *
     * @generated from protobuf rpc: UnsubscribeFromEmail(verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailRequest) returns (verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailResponse);
     */
    unsubscribeFromEmail(input: UnsubscribeFromEmailRequest, options?: RpcOptions): UnaryCall<UnsubscribeFromEmailRequest, UnsubscribeFromEmailResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnsubscribeFromEmailRequest, UnsubscribeFromEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns all notification user preferences for a participant.
     * Accepts an unsubscribe token instead of CIAM context to validate a user's identity.
     * Used specifically during the unauthenticated unsubscribe flow.
     *
     * @generated from protobuf rpc: ListNotificationUserPreferencesUnauthenticated(verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesUnauthenticatedRequest) returns (verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse);
     */
    listNotificationUserPreferencesUnauthenticated(input: ListNotificationUserPreferencesUnauthenticatedRequest, options?: RpcOptions): UnaryCall<ListNotificationUserPreferencesUnauthenticatedRequest, ListNotificationUserPreferencesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListNotificationUserPreferencesUnauthenticatedRequest, ListNotificationUserPreferencesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Sets one or more notification user preferences.
     * Used specifically during the unauthenticated unsubscribe flow.
     *
     * @generated from protobuf rpc: BatchUpdateNotificationUserPreferencesUnauthenticated(verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedRequest) returns (verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedResponse);
     */
    batchUpdateNotificationUserPreferencesUnauthenticated(input: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, options?: RpcOptions): UnaryCall<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, BatchUpdateNotificationUserPreferencesUnauthenticatedResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, BatchUpdateNotificationUserPreferencesUnauthenticatedResponse>("unary", this._transport, method, opt, input);
    }
}
