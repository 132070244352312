/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service.proto" (package "verily_me.web.userprofile.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesRequest
 */
export interface ListNotificationUserPreferencesRequest {
    /**
     * List user preferences broken down by category.
     * For MFEs that support category-based preferences, this field
     * should always be set to true.
     *
     * @generated from protobuf field: bool list_by_category = 1;
     */
    listByCategory: boolean;
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesRequest
 */
export interface BatchUpdateNotificationUserPreferencesRequest {
    /**
     * The request message specifying the notification user preference to update.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests = 1;
     */
    requests: UpdateNotificationUserPreferenceRequest[];
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest
 */
export interface UpdateNotificationUserPreferenceRequest {
    /**
     * The notification user preference to update.
     *
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preference = 1;
     */
    preference?: NotificationUserPreference;
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailRequest
 */
export interface UnsubscribeFromEmailRequest {
    /**
     * Communication identifier found in the unsubscribe URL.
     *
     * @generated from protobuf field: string communication_identifier = 1;
     */
    communicationIdentifier: string;
    /**
     * An unsubscribe hash found in the unsubscribe URL.
     *
     * @generated from protobuf field: string unsubscribe_hash = 2;
     */
    unsubscribeHash: string;
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesUnauthenticatedRequest
 */
export interface ListNotificationUserPreferencesUnauthenticatedRequest {
    /**
     * Communication identifier found in the unsubscribe URL.
     *
     * @generated from protobuf field: string communication_identifier = 1;
     */
    communicationIdentifier: string;
    /**
     * An unsubscribe hash found in the unsubscribe URL.
     *
     * @generated from protobuf field: string unsubscribe_hash = 2;
     */
    unsubscribeHash: string;
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedRequest
 */
export interface BatchUpdateNotificationUserPreferencesUnauthenticatedRequest {
    /**
     * Communication identifier found in the unsubscribe URL.
     *
     * @generated from protobuf field: string communication_identifier = 1;
     */
    communicationIdentifier: string;
    /**
     * An unsubscribe hash found in the unsubscribe URL.
     *
     * @generated from protobuf field: string unsubscribe_hash = 2;
     */
    unsubscribeHash: string;
    /**
     * The request message specifying the notification user preference to update.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests = 3;
     */
    requests: UpdateNotificationUserPreferenceRequest[];
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse
 */
export interface ListNotificationUserPreferencesResponse {
    /**
     * The notification user preferences for a participant.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1;
     */
    preferences: NotificationUserPreference[];
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesResponse
 */
export interface BatchUpdateNotificationUserPreferencesResponse {
    /**
     * Notification user preferences updated.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1;
     */
    preferences: NotificationUserPreference[];
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailResponse
 */
export interface UnsubscribeFromEmailResponse {
}
/**
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedResponse
 */
export interface BatchUpdateNotificationUserPreferencesUnauthenticatedResponse {
    /**
     * Notification user preferences updated.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1;
     */
    preferences: NotificationUserPreference[];
}
/**
 * Represents the state of one notification user preference.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.NotificationUserPreference
 */
export interface NotificationUserPreference {
    /**
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationChannel channel = 1;
     */
    channel: NotificationUserPreference_NotificationChannel;
    /**
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue value = 2;
     */
    value?: NotificationUserPreference_NotificationPreferenceValue;
    /**
     * Indicates whether the notification user preference has been set yet.
     * If false, this notification user preference has never been set for this user before.
     * If true, this notification user preference has been set for this user at least once.
     * This field is ignored if set on a BatchUpdateNotificationUserPreferences request.
     *
     * @generated from protobuf field: bool status_set = 3;
     */
    statusSet: boolean;
    /**
     * Category of the notification user preference.
     *
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationCategory category = 4;
     */
    category: NotificationUserPreference_NotificationCategory;
}
/**
 * Indicates the value associated with the preference.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue
 */
export interface NotificationUserPreference_NotificationPreferenceValue {
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "enabled";
        /**
         * @generated from protobuf field: bool enabled = 1;
         */
        enabled: boolean;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Channel of the notification user preference.
 *
 * @generated from protobuf enum verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationChannel
 */
export enum NotificationUserPreference_NotificationChannel {
    /**
     * @generated from protobuf enum value: NOTIFICATION_CHANNEL_UNSPECIFIED = 0;
     */
    NOTIFICATION_CHANNEL_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: EMAIL = 1;
     */
    EMAIL = 1,
    /**
     * @generated from protobuf enum value: PUSH = 2;
     */
    PUSH = 2
}
/**
 * Category of the notification.
 *
 * @generated from protobuf enum verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationCategory
 */
export enum NotificationUserPreference_NotificationCategory {
    /**
     * @generated from protobuf enum value: NOTIFICATION_CATEGORY_UNSPECIFIED = 0;
     */
    NOTIFICATION_CATEGORY_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: TASK = 1;
     */
    TASK = 1,
    /**
     * @generated from protobuf enum value: MESSAGE = 2;
     */
    MESSAGE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationUserPreferencesRequest$Type extends MessageType<ListNotificationUserPreferencesRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesRequest", [
            { no: 1, name: "list_by_category", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListNotificationUserPreferencesRequest>): ListNotificationUserPreferencesRequest {
        const message = { listByCategory: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListNotificationUserPreferencesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNotificationUserPreferencesRequest): ListNotificationUserPreferencesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool list_by_category */ 1:
                    message.listByCategory = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNotificationUserPreferencesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool list_by_category = 1; */
        if (message.listByCategory !== false)
            writer.tag(1, WireType.Varint).bool(message.listByCategory);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesRequest
 */
export const ListNotificationUserPreferencesRequest = new ListNotificationUserPreferencesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateNotificationUserPreferencesRequest$Type extends MessageType<BatchUpdateNotificationUserPreferencesRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesRequest", [
            { no: 1, name: "requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UpdateNotificationUserPreferenceRequest, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<BatchUpdateNotificationUserPreferencesRequest>): BatchUpdateNotificationUserPreferencesRequest {
        const message = { requests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchUpdateNotificationUserPreferencesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchUpdateNotificationUserPreferencesRequest): BatchUpdateNotificationUserPreferencesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests */ 1:
                    message.requests.push(UpdateNotificationUserPreferenceRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchUpdateNotificationUserPreferencesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests = 1; */
        for (let i = 0; i < message.requests.length; i++)
            UpdateNotificationUserPreferenceRequest.internalBinaryWrite(message.requests[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesRequest
 */
export const BatchUpdateNotificationUserPreferencesRequest = new BatchUpdateNotificationUserPreferencesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateNotificationUserPreferenceRequest$Type extends MessageType<UpdateNotificationUserPreferenceRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest", [
            { no: 1, name: "preference", kind: "message", T: () => NotificationUserPreference, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<UpdateNotificationUserPreferenceRequest>): UpdateNotificationUserPreferenceRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateNotificationUserPreferenceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateNotificationUserPreferenceRequest): UpdateNotificationUserPreferenceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preference */ 1:
                    message.preference = NotificationUserPreference.internalBinaryRead(reader, reader.uint32(), options, message.preference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateNotificationUserPreferenceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preference = 1; */
        if (message.preference)
            NotificationUserPreference.internalBinaryWrite(message.preference, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest
 */
export const UpdateNotificationUserPreferenceRequest = new UpdateNotificationUserPreferenceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsubscribeFromEmailRequest$Type extends MessageType<UnsubscribeFromEmailRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailRequest", [
            { no: 1, name: "communication_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "unsubscribe_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<UnsubscribeFromEmailRequest>): UnsubscribeFromEmailRequest {
        const message = { communicationIdentifier: "", unsubscribeHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UnsubscribeFromEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnsubscribeFromEmailRequest): UnsubscribeFromEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string communication_identifier */ 1:
                    message.communicationIdentifier = reader.string();
                    break;
                case /* string unsubscribe_hash */ 2:
                    message.unsubscribeHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnsubscribeFromEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string communication_identifier = 1; */
        if (message.communicationIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.communicationIdentifier);
        /* string unsubscribe_hash = 2; */
        if (message.unsubscribeHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.unsubscribeHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailRequest
 */
export const UnsubscribeFromEmailRequest = new UnsubscribeFromEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationUserPreferencesUnauthenticatedRequest$Type extends MessageType<ListNotificationUserPreferencesUnauthenticatedRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesUnauthenticatedRequest", [
            { no: 1, name: "communication_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "unsubscribe_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<ListNotificationUserPreferencesUnauthenticatedRequest>): ListNotificationUserPreferencesUnauthenticatedRequest {
        const message = { communicationIdentifier: "", unsubscribeHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListNotificationUserPreferencesUnauthenticatedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNotificationUserPreferencesUnauthenticatedRequest): ListNotificationUserPreferencesUnauthenticatedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string communication_identifier */ 1:
                    message.communicationIdentifier = reader.string();
                    break;
                case /* string unsubscribe_hash */ 2:
                    message.unsubscribeHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNotificationUserPreferencesUnauthenticatedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string communication_identifier = 1; */
        if (message.communicationIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.communicationIdentifier);
        /* string unsubscribe_hash = 2; */
        if (message.unsubscribeHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.unsubscribeHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesUnauthenticatedRequest
 */
export const ListNotificationUserPreferencesUnauthenticatedRequest = new ListNotificationUserPreferencesUnauthenticatedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateNotificationUserPreferencesUnauthenticatedRequest$Type extends MessageType<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedRequest", [
            { no: 1, name: "communication_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "unsubscribe_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UpdateNotificationUserPreferenceRequest, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest>): BatchUpdateNotificationUserPreferencesUnauthenticatedRequest {
        const message = { communicationIdentifier: "", unsubscribeHash: "", requests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchUpdateNotificationUserPreferencesUnauthenticatedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest): BatchUpdateNotificationUserPreferencesUnauthenticatedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string communication_identifier */ 1:
                    message.communicationIdentifier = reader.string();
                    break;
                case /* string unsubscribe_hash */ 2:
                    message.unsubscribeHash = reader.string();
                    break;
                case /* repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests */ 3:
                    message.requests.push(UpdateNotificationUserPreferenceRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string communication_identifier = 1; */
        if (message.communicationIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.communicationIdentifier);
        /* string unsubscribe_hash = 2; */
        if (message.unsubscribeHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.unsubscribeHash);
        /* repeated verily_me.web.userprofile.bff.api.v1.UpdateNotificationUserPreferenceRequest requests = 3; */
        for (let i = 0; i < message.requests.length; i++)
            UpdateNotificationUserPreferenceRequest.internalBinaryWrite(message.requests[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedRequest
 */
export const BatchUpdateNotificationUserPreferencesUnauthenticatedRequest = new BatchUpdateNotificationUserPreferencesUnauthenticatedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationUserPreferencesResponse$Type extends MessageType<ListNotificationUserPreferencesResponse> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse", [
            { no: 1, name: "preferences", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationUserPreference }
        ]);
    }
    create(value?: PartialMessage<ListNotificationUserPreferencesResponse>): ListNotificationUserPreferencesResponse {
        const message = { preferences: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListNotificationUserPreferencesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNotificationUserPreferencesResponse): ListNotificationUserPreferencesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences */ 1:
                    message.preferences.push(NotificationUserPreference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNotificationUserPreferencesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1; */
        for (let i = 0; i < message.preferences.length; i++)
            NotificationUserPreference.internalBinaryWrite(message.preferences[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ListNotificationUserPreferencesResponse
 */
export const ListNotificationUserPreferencesResponse = new ListNotificationUserPreferencesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateNotificationUserPreferencesResponse$Type extends MessageType<BatchUpdateNotificationUserPreferencesResponse> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesResponse", [
            { no: 1, name: "preferences", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationUserPreference }
        ]);
    }
    create(value?: PartialMessage<BatchUpdateNotificationUserPreferencesResponse>): BatchUpdateNotificationUserPreferencesResponse {
        const message = { preferences: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchUpdateNotificationUserPreferencesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchUpdateNotificationUserPreferencesResponse): BatchUpdateNotificationUserPreferencesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences */ 1:
                    message.preferences.push(NotificationUserPreference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchUpdateNotificationUserPreferencesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1; */
        for (let i = 0; i < message.preferences.length; i++)
            NotificationUserPreference.internalBinaryWrite(message.preferences[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesResponse
 */
export const BatchUpdateNotificationUserPreferencesResponse = new BatchUpdateNotificationUserPreferencesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsubscribeFromEmailResponse$Type extends MessageType<UnsubscribeFromEmailResponse> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailResponse", []);
    }
    create(value?: PartialMessage<UnsubscribeFromEmailResponse>): UnsubscribeFromEmailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UnsubscribeFromEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnsubscribeFromEmailResponse): UnsubscribeFromEmailResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnsubscribeFromEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.UnsubscribeFromEmailResponse
 */
export const UnsubscribeFromEmailResponse = new UnsubscribeFromEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateNotificationUserPreferencesUnauthenticatedResponse$Type extends MessageType<BatchUpdateNotificationUserPreferencesUnauthenticatedResponse> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedResponse", [
            { no: 1, name: "preferences", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationUserPreference }
        ]);
    }
    create(value?: PartialMessage<BatchUpdateNotificationUserPreferencesUnauthenticatedResponse>): BatchUpdateNotificationUserPreferencesUnauthenticatedResponse {
        const message = { preferences: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchUpdateNotificationUserPreferencesUnauthenticatedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchUpdateNotificationUserPreferencesUnauthenticatedResponse): BatchUpdateNotificationUserPreferencesUnauthenticatedResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences */ 1:
                    message.preferences.push(NotificationUserPreference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchUpdateNotificationUserPreferencesUnauthenticatedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.userprofile.bff.api.v1.NotificationUserPreference preferences = 1; */
        for (let i = 0; i < message.preferences.length; i++)
            NotificationUserPreference.internalBinaryWrite(message.preferences[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.BatchUpdateNotificationUserPreferencesUnauthenticatedResponse
 */
export const BatchUpdateNotificationUserPreferencesUnauthenticatedResponse = new BatchUpdateNotificationUserPreferencesUnauthenticatedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationUserPreference$Type extends MessageType<NotificationUserPreference> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.NotificationUserPreference", [
            { no: 1, name: "channel", kind: "enum", T: () => ["verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationChannel", NotificationUserPreference_NotificationChannel] },
            { no: 2, name: "value", kind: "message", T: () => NotificationUserPreference_NotificationPreferenceValue },
            { no: 3, name: "status_set", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "category", kind: "enum", T: () => ["verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationCategory", NotificationUserPreference_NotificationCategory] }
        ]);
    }
    create(value?: PartialMessage<NotificationUserPreference>): NotificationUserPreference {
        const message = { channel: 0, statusSet: false, category: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationUserPreference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationUserPreference): NotificationUserPreference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationChannel channel */ 1:
                    message.channel = reader.int32();
                    break;
                case /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue value */ 2:
                    message.value = NotificationUserPreference_NotificationPreferenceValue.internalBinaryRead(reader, reader.uint32(), options, message.value);
                    break;
                case /* bool status_set */ 3:
                    message.statusSet = reader.bool();
                    break;
                case /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationCategory category */ 4:
                    message.category = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationUserPreference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationChannel channel = 1; */
        if (message.channel !== 0)
            writer.tag(1, WireType.Varint).int32(message.channel);
        /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue value = 2; */
        if (message.value)
            NotificationUserPreference_NotificationPreferenceValue.internalBinaryWrite(message.value, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool status_set = 3; */
        if (message.statusSet !== false)
            writer.tag(3, WireType.Varint).bool(message.statusSet);
        /* verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationCategory category = 4; */
        if (message.category !== 0)
            writer.tag(4, WireType.Varint).int32(message.category);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.NotificationUserPreference
 */
export const NotificationUserPreference = new NotificationUserPreference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationUserPreference_NotificationPreferenceValue$Type extends MessageType<NotificationUserPreference_NotificationPreferenceValue> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue", [
            { no: 1, name: "enabled", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationUserPreference_NotificationPreferenceValue>): NotificationUserPreference_NotificationPreferenceValue {
        const message = { value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationUserPreference_NotificationPreferenceValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationUserPreference_NotificationPreferenceValue): NotificationUserPreference_NotificationPreferenceValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.value = {
                        oneofKind: "enabled",
                        enabled: reader.bool()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationUserPreference_NotificationPreferenceValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.value.oneofKind === "enabled")
            writer.tag(1, WireType.Varint).bool(message.value.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.NotificationUserPreference.NotificationPreferenceValue
 */
export const NotificationUserPreference_NotificationPreferenceValue = new NotificationUserPreference_NotificationPreferenceValue$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.userprofile.bff.api.v1.NotificationUserPreferencesService
 */
export const NotificationUserPreferencesService = new ServiceType("verily_me.web.userprofile.bff.api.v1.NotificationUserPreferencesService", [
    { name: "ListNotificationUserPreferences", options: {}, I: ListNotificationUserPreferencesRequest, O: ListNotificationUserPreferencesResponse },
    { name: "BatchUpdateNotificationUserPreferences", options: {}, I: BatchUpdateNotificationUserPreferencesRequest, O: BatchUpdateNotificationUserPreferencesResponse },
    { name: "UnsubscribeFromEmail", options: {}, I: UnsubscribeFromEmailRequest, O: UnsubscribeFromEmailResponse },
    { name: "ListNotificationUserPreferencesUnauthenticated", options: {}, I: ListNotificationUserPreferencesUnauthenticatedRequest, O: ListNotificationUserPreferencesResponse },
    { name: "BatchUpdateNotificationUserPreferencesUnauthenticated", options: {}, I: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest, O: BatchUpdateNotificationUserPreferencesUnauthenticatedResponse }
]);
