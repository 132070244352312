// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agreementsListContainer__mRycL{margin-bottom:56px;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/components/Agreements/Agreements.module.css"],"names":[],"mappings":"AAAA,gCAEE,kBAAmB,CADnB,eAEF","sourcesContent":[".agreementsListContainer {\n  margin-top: 16px;\n  margin-bottom: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreementsListContainer": `agreementsListContainer__mRycL`
};
export default ___CSS_LOADER_EXPORT___;
