import {Box, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {Card, ContactSupportIcon} from '@verily-src/react-design-system';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';

export default function Support({isLoading}: {isLoading: boolean}) {
  const {t} = useTranslation();
  const theme = useTheme();
  const stonlyUrl = config.getString('STONLY_URL');

  return (
    <div style={{marginTop: theme.spacing(2)}}>
      {isLoading ? (
        <div
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          <PillSkeleton
            width={theme.spacing(12.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <Typography
          component={'h2'}
          sx={{paddingY: theme.spacing(2)}}
          variant="display6"
        >
          {t('support')}
        </Typography>
      )}
      <Box sx={{width: '100%', paddingTop: theme.spacing(1)}}>
        <Card
          pendoId="how-verily-me-works-button"
          title={isLoading ? '' : t('how-verily-me-works')}
          action={{
            // TODO(ONEVERILY-18544): Make external links (e.g in Profile) links instead of onClick
            // handlers
            onClick: () => {
              if (!isLoading) {
                window.open(stonlyUrl, '_blank').focus();
              }
            },
            hasIcon: true,
            'aria-label': isLoading && t('spinner-label'),
          }}
          data-testid="how-verily-me-works-container"
          useUnlaunchedComponent
          IconComponent={
            !isLoading && (
              <ContactSupportIcon sx={{color: theme.palette.primary.main}} />
            )
          }
          loadingVisual={
            isLoading && (
              <PillSkeleton
                width={theme.spacing(13)}
                height={theme.spacing(2)}
              />
            )
          }
        />
      </Box>
    </div>
  );
}
