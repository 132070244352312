import {RpcError} from '@protobuf-ts/runtime-rpc';

export function isAuthenticationError(err: Error) {
  if (err.name !== 'RpcError') {
    return false;
  }

  const rpcErr = err as RpcError;
  return (
    rpcErr.code === 'UNAUTHENTICATED' || rpcErr.code === 'PERMISSION_DENIED'
  );
}
