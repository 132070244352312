import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {LogErrorRequest_Severity} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/logger_service';

export default (
  error: Error,
  componentName: string,
  severity: LogErrorRequest_Severity
) => {
  console.error(error);
  (api as typeof VerilyMeApi).logger.logError(
    error.name,
    error?.stack || '',
    window.location.href,
    componentName,
    severity
  );
};
