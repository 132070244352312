import {useEffect, useState} from 'react';

const useLocale = () => {
  const [locale, setLocale] = useState(navigator.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLocale(navigator.language);
    };

    window.addEventListener('languagechange', handleLanguageChange);

    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  return locale;
};

export default useLocale;
