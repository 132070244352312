import {LoginStatus} from '@verily-src/auth'; // eslint-disable-line node/no-extraneous-import
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';

export const onLogout = (...callbacks: (() => void)[]) => {
  const loginStatus = (api as typeof VerilyMeApi).auth.loginStatus$;
  loginStatus.subscribe(value => {
    if (value === LoginStatus.LoggedOut) {
      callbacks.forEach(callback => callback());
    }
  });
};
