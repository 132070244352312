import {CircularProgress} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {UserProfile} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import LogOutModal from '@verily-src/verily1-verily-me-web-common-typescript/src/components/LogOutModal/LogOutModal';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import Account from '../../components/Account/Account';
import Agreements from '../../components/Agreements/Agreements';
import LogOut from '../../components/LogOut/LogOut';
import ResearchStudies from '../../components/ResearchStudies/ResearchStudies';
import Support from '../../components/Support/Support';
import UserName from '../../components/UserName/UserName';
import UserProfileService from '../../services/UserProfileService';
import logError from '../../utils/logError';

const PROFILE_INTERVAL_MS = 5000;

export default function Profile() {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const {t} = useTranslation();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('profile-header')}`;
    loadProfile(true);
  }, []);

  async function loadProfile(initialLoad?: boolean) {
    if (initialLoad) setIsLoadingData(true);
    const {cached, fresh} = UserProfileService.getUserProfile();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updateProfile(cached);
    // Update state to use actual Profile whenever available
    fresh.then(updateProfile).catch(err => {
      logError(err);

      if (!isAuthenticationError(err)) {
        // Attempt to load the profile data again in 5 seconds
        setTimeout(() => loadProfile(), PROFILE_INTERVAL_MS);
        setIsLoadingData(false);
      }
    });
  }

  const updateProfile = (profile: UserProfile) => {
    setUserProfile(profile);
    setIsLoadingData(false);
  };

  return (
    <Layout header={<ProfileHeader />}>
      {!isLoadingData && !userProfile ? (
        <ErrorView
          title={t('something-went-wrong')}
          subtitle={t('were-having-an-issue')}
          refreshText={t('refresh')}
          logOutPrompt={{
            description: t('log-out-confirmation-text'),
            logOutText: t('log-out-button'),
            cancelText: t('cancel-log-out-button'),
          }}
        />
      ) : (
        <ProfileContent userProfile={userProfile} isLoading={isLoadingData} />
      )}
    </Layout>
  );
}

export interface ProfileContentProps {
  isLoading: boolean;
  userProfile: UserProfile;
}

export function ProfileContent(props: ProfileContentProps) {
  const {t} = useTranslation();
  const {userProfile, isLoading} = props;
  const skeletonsEnabled = config.getBoolean('FEATURE_SKELETONS_ENABLED');

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFullName = () => {
    if (!userProfile) return '';
    if (userProfile.name.givenNames?.length > 0) {
      return `${userProfile.name.givenNames[0]} ${userProfile.name.familyName}`;
    }
    return userProfile.name.familyName;
  };

  const researchSectionVisible = userProfile?.researchStudies?.length > 0;

  if (isLoading && !skeletonsEnabled) {
    return (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  return (
    <>
      <section data-testid="username-section">
        <UserName
          fullName={getFullName()}
          initials={userProfile?.name.initials || ''}
          isLoading={isLoading}
          verificationState={userProfile?.verificationState}
        />
      </section>
      <section>
        <Account
          email={userProfile?.emailAddress || ''}
          isLoading={isLoading}
        />
      </section>
      {researchSectionVisible && (
        <section>
          <ResearchStudies
            researchStudies={userProfile?.researchStudies}
            onClick={study => {
              const newUrl = new URL(window.location.href);
              newUrl.searchParams.set('studyId', study.studyId);
              newUrl.searchParams.set('subjectId', study.subjectId);
              newUrl.pathname = '/me/profile/research-study';

              navigateToUrl(newUrl.toString());
            }}
            isLoading={isLoading}
          />
        </section>
      )}
      <section>
        <Support isLoading={isLoading} />
      </section>
      <section>
        <Agreements
          agreements={userProfile ? userProfile.agreements : []}
          isLoading={isLoading}
        />
      </section>
      <section>
        <LogOut isLoading={isLoading} onLogOut={handleOpen} />
        <LogOutModal
          open={open}
          onClose={handleClose}
          prompt={{
            description: t('log-out-confirmation-text'),
            logOutText: t('log-out-button'),
            cancelText: t('cancel-log-out-button'),
          }}
        />
      </section>
    </>
  );
}

function ProfileHeader() {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  return (
    <Header
      headerText={t('profile-header')}
      hasBackButton={isMobile}
      onBackButtonClick={() => (window.location.href = '/me/home')}
      backButtonDescription={t('back-button-description')}
    />
  );
}
