import {Skeleton, useTheme} from '@mui/material';

type PillSkeletonProps = {
  width: string;
  height: string;
  animation?: false | 'pulse' | 'wave';
};

const PillSkeleton = ({
  width,
  height,
  animation = false,
}: PillSkeletonProps) => {
  const theme = useTheme();

  return (
    <Skeleton
      sx={{
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.background.secondaryCanvas,
      }}
      animation={animation}
      variant="rounded"
      width={width}
      height={height}
    />
  );
};

export default PillSkeleton;
