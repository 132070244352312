import {Box, Typography, useTheme} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import {useState} from 'react';
import LogOutModal from '../LogOutModal/LogOutModal';
import ErrorImage from './ErrorImage';

type ErrorViewProps = {
  title: string;
  subtitle: string;
  refreshText: string;
  logOutPrompt?: {
    description: string;
    logOutText: string;
    cancelText: string;
  };
};

export default function ErrorView({
  title,
  subtitle,
  refreshText,
  logOutPrompt,
}: ErrorViewProps) {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
      data-testid="error-box"
    >
      <Box
        sx={{
          p: 7,
        }}
      >
        {ErrorImage}
      </Box>
      <Typography variant="display5" component="h2" sx={{mb: 2}}>
        {title}
      </Typography>
      <Typography variant="body1" color={theme.palette.text.muted} sx={{mb: 3}}>
        {subtitle}
      </Typography>
      <div>
        <Button onClick={() => window.location.reload()}>{refreshText}</Button>
      </div>
      {!!logOutPrompt && (
        <>
          <div>
            <Button onClick={() => setModalOpen(true)}>
              {logOutPrompt.logOutText}
            </Button>
          </div>
          <LogOutModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            prompt={logOutPrompt}
          />
        </>
      )}
    </Box>
  );
}
