import {CssBaseline, ThemeProvider} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';
import useLocale from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useLocale';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import EmailUnsubscribe from '../../pages/EmailUnsubscribe/EmailUnsubscribe';
import NotificationPreferences from '../../pages/NotificationPreferences/NotificationPreferences';
import Profile from '../../pages/Profile/Profile';
import ResearchStudy from '../../pages/ResearchStudy/ResearchStudy';
import UpdatePassword from '../../pages/UpdatePassword/UpdatePassword';

export default function Root() {
  const locale = useLocale();

  return (
    <ThemeProvider
      theme={{
        ...ConsumerLightTheme,
        locale,
      }}
    >
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/me/profile" element={<Profile />}></Route>
          <Route
            path="/me/profile/update-password"
            element={<UpdatePassword />}
          ></Route>
          <Route
            path="/me/profile/research-study"
            element={<ResearchStudy />}
          ></Route>
          <Route
            path="/me/profile/notification-preferences"
            element={<NotificationPreferences />}
          ></Route>
          <Route
            path="/email/unsubscribe"
            element={<EmailUnsubscribe />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
