// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountContainer__U37gR{margin-top:8px;padding-top:16px}.emailContainer__hq4RJ{flex-direction:column}.emailContainer__hq4RJ,.passwordContainer__JyiDm{background-color:#fff;display:flex;padding:10px}.passwordContainer__JyiDm{align-items:center;justify-content:space-between}.button__OVLg3{background-color:inherit;border:none;cursor:pointer;font-size:16px}`, "",{"version":3,"sources":["webpack://./src/components/Account/Account.module.css"],"names":[],"mappings":"AAAA,yBACE,cAAe,CACf,gBACF,CAEA,uBAEE,qBAGF,CAEA,iDAHE,qBAAuB,CAHvB,YAAa,CAEb,YAUF,CANA,0BAGE,kBAAmB,CADnB,6BAIF,CAEA,eAEE,wBAAyB,CADzB,WAAY,CAGZ,cAAe,CADf,cAEF","sourcesContent":[".accountContainer {\n  margin-top: 8px;\n  padding-top: 16px;\n}\n\n.emailContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  background-color: white;\n}\n\n.passwordContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  background-color: white;\n}\n\n.button {\n  border: none;\n  background-color: inherit;\n  font-size: 16px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountContainer": `accountContainer__U37gR`,
	"emailContainer": `emailContainer__hq4RJ`,
	"passwordContainer": `passwordContainer__JyiDm`,
	"button": `button__OVLg3`
};
export default ___CSS_LOADER_EXPORT___;
