import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {Card, TextSnippetIcon} from '@verily-src/react-design-system';
import {Agreement} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';
import styles from './Agreements.module.css';

type AgreementsProps = {
  agreements: Agreement[];
  isLoading: boolean;
};

export default function Agreements({agreements, isLoading}: AgreementsProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <div className={styles.agreementsListContainer}>
      {isLoading ? (
        <div
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          <PillSkeleton
            width={theme.spacing(12.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <Typography
          component={'h2'}
          sx={{paddingY: theme.spacing(2)}}
          variant="display6"
        >
          {t('privacy-and-legal')}
        </Typography>
      )}
      <Box sx={{width: '100%', paddingTop: theme.spacing(1)}}>
        <Stack spacing={1}>
          {isLoading ? (
            <SkeletonAgreements />
          ) : (
            agreements.map(agreement => (
              <Card
                key={agreement.id}
                pendo-agreement-consent-id={agreement.consentId}
                pendo-agreement-consent-button-title={agreement.displayName}
                pendo-agreement-url={agreement.url}
                data-testid="agreement-container"
                title={agreement.displayName}
                useUnlaunchedComponent
                IconComponent={
                  <TextSnippetIcon sx={{color: theme.palette.primary.main}} />
                }
                action={{
                  'aria-label': agreement.displayName,
                  onClick: () => {
                    window.open(agreement.url, '_blank');
                  },
                  hasIcon: true,
                }}
              />
            ))
          )}
        </Stack>
      </Box>
    </div>
  );
}

const SkeletonAgreements = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  return (
    <>
      <Card
        title={''}
        useUnlaunchedComponent
        action={{
          onClick: () => {},
          hasIcon: true,
          'aria-label': t('spinner-label'),
        }}
        loadingVisual={
          <PillSkeleton width={theme.spacing(22.5)} height={theme.spacing(2)} />
        }
      />
      <Card
        title={''}
        useUnlaunchedComponent
        action={{
          onClick: () => {},
          hasIcon: true,
          'aria-label': t('spinner-label'),
        }}
        loadingVisual={
          <PillSkeleton width={theme.spacing(22.5)} height={theme.spacing(2)} />
        }
      />
    </>
  );
};
