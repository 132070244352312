import {Box, Modal, Typography} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {Button} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';

type LogOutModalProps = {
  open: boolean;
  onClose: () => void;
  prompt: {
    description: string;
    logOutText: string;
    cancelText: string;
  };
};

export default function LogOutModal({open, onClose, prompt}: LogOutModalProps) {
  const handleLogout = () => (api as typeof VerilyMeApi).auth.logout();

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: 'auto',
    minWidth: 380,

    // Prevent x-overflow on very small screens
    '@media (max-width: 380px)': {
      width: '100%',
      minWidth: '0',
      px: 1,
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box id="logout-box" sx={modalStyle}>
        <Typography id="modal-modal-description" sx={{mt: 2}}>
          {prompt.description}
        </Typography>
        <Typography sx={{display: 'flex'}} variant="body2">
          <Button
            id="cancel-logout"
            style={{marginLeft: 'auto', fontFamily: 'inherit'}}
            onClick={onClose}
            aria-label="Cancel"
          >
            {prompt.cancelText}
          </Button>
          <Button
            id="logout2"
            style={{fontFamily: 'inherit'}}
            onClick={handleLogout}
            aria-label="Log Out"
          >
            {prompt.logOutText}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}
