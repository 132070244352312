import {default as i18n, Resource, TFunction} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {PseudoLocalePlugin} from './pseudo_plugin';

export const PSEUDO_LOCALE = 'xa';

export const DEFAULT_LOCALES = ['en-US', 'es-US'];

export const DEFAULT_NAMESPACES = ['defaultNS'];

export const NAV_NAMESPACES = ['nav'];

type TranslationResource = {projectID: string} & {
  [key: string]: {content: string};
};

/**
 * Initializes the i18next translation system
 * `requireFn` should be a function that takes in a file path relative to your
 * 'locales' directory and `require`s it, relative to the path of the file
 * calling `init`.
 * e.g. if your 'locales' directory is at `verily1/myteam/l10n/locales`, and `init`
 * is being called from `verily1/myteam/mfe/src/initL10n.ts`, then your init call
 * should look like:
 *   init(f => require(`../../l10n/locales/${f}`));
 * (this is understandably a bit janky but necessary due to the way webpack
 * handles `require` calls)
 * `locales` is a list of supported locales. You should have a directory in your
 * `locales` dir for each provided locale. The only exception is the 'xa'
 * locale, which is a special pseudolocale intended for testing whose content is
 * generated programatically.
 */
export async function init(
  requireFn: (string) => TranslationResource,
  locales: string[] = DEFAULT_LOCALES,
  namespaces: string[] = DEFAULT_NAMESPACES,
  langOverride: string | undefined = undefined
): Promise<TFunction> {
  const resources: {
    locale: string;
    ns: string;
    resource: TranslationResource;
  }[] = [];

  const realLocales = locales.filter(l => l !== PSEUDO_LOCALE);
  for (const locale of realLocales) {
    for (const ns of namespaces) {
      const resource = requireFn(`${locale}/${ns}.json`);
      resources.push({locale, ns, resource});
    }
  }

  const bundle: Resource = {};
  for (const {locale, ns, resource} of resources) {
    bundle[locale] ??= {};
    bundle[locale][ns] ??= {};
    const translationKeys = Object.keys(resource).filter(
      k => k !== 'projectID'
    );
    for (const key of translationKeys) {
      bundle[locale][ns][key] = resource[key].content;
    }
  }

  const postProcessModules: string[] = [];
  let mod = i18n.use(LanguageDetector).use(initReactI18next);

  const includePseudoLocale = locales.includes(PSEUDO_LOCALE);
  if (includePseudoLocale) {
    const plugin = new PseudoLocalePlugin({localeName: PSEUDO_LOCALE});
    postProcessModules.push(plugin.name);
    mod = mod.use(plugin);
  }

  return mod.init({
    lng: langOverride,
    detection: {order: ['querystring', 'navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain']},
    ns: 'defaultNS',
    fallbackLng: {
      en: ['en-US'],
      es: ['es-US', 'en-US'],
      default: ['en-US'],
    },
    interpolation: {
      escapeValue: false, // react already performs sanitization
    },
    resources: bundle,
    postProcess: postProcessModules,
  });
}
