import {IconButton, Typography, useTheme} from '@mui/material';
import {CloseIcon, Dialog} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';

export function PreferencesOffConfirmationDialog({
  dismissAction,
  confirmAction,
}: {
  dismissAction: () => void;
  confirmAction: () => void;
}) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      onClose={dismissAction}
      header={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          data-testid="unsubscribe-confirmation-header"
        >
          <Typography
            component="h1"
            variant="h5"
            style={{fontWeight: 500, fontFamily: 'Poppins, sans-serif'}}
          >
            {t('unsubscribe-confirmation-title')}
          </Typography>
          <IconButton
            size="small"
            sx={{
              position: 'relative',
              backgroundColor: '#DAD7D1',
              '&:hover': {backgroundColor: '#C4C1bC'},
              ':focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 48,
                height: 48,
                borderRadius: '50%',
              },
            }}
            onClick={dismissAction}
            data-testid="unsubscribe-confirmation-close-button"
            aria-label={t('close')}
            title={t('close')}
          >
            <CloseIcon />
          </IconButton>
        </div>
      }
      contentText={t('unsubscribe-confirmation-body')}
      size="medium"
      primaryAction={{
        text: t('unsubscribe-confirmation-yes'),
        callback: confirmAction,
      }}
      secondaryAction={{
        text: t('unsubscribe-confirmation-cancel'),
        callback: dismissAction,
      }}
      slotProps={{backdrop: {style: {opacity: 0.7}}}}
    ></Dialog>
  );
}
