import {
  Box,
  Container,
  IconButton,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material';
import {ArrowBackIcon} from '@verily-src/react-design-system';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import useIsMobile from '../../utilities/useIsMobile';
import PillSkeleton from '../Skeleton/PillSkeleton';

type HeaderProps = {
  headerText: string;
  headerVariant?: TypographyVariant;
  hasBackButton: boolean;
  onBackButtonClick?: () => void;
  backButtonDescription?: string;
  color?: string;
  loading?: boolean;
};

const Header = ({
  headerText,
  headerVariant,
  children,
  hasBackButton,
  onBackButtonClick,
  backButtonDescription,
  color,
  loading,
}: PropsWithChildren<HeaderProps>) => {
  const theme = useTheme();

  const isMobile = useIsMobile();
  const [isWindowLargerThanHeaderHeight, setIsWindowLargerThanHeaderHeight] =
    useState(true);

  const [isScrolled, setIsScrolled] = useState(false);

  // used to detect when the top of the page is hit
  const sentinelRef = useCallback((node: HTMLDivElement) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsScrolled(!entry.isIntersecting); // if the top of the page is hit, isIntersecting is true
      },
      {
        root: null,
      }
    );

    if (node) {
      observer.observe(node);
    }

    // Normally we would want to disconnect the observer when the component unmounts,
    // but MutationObservers only have weak references to their targets. We assume
    // that this applies to IntersectionObservers as well, so we don't need to
    // disconnect it. https://dom.spec.whatwg.org/#interface-mutationobserver
  }, []);

  const headerTextVariant =
    headerVariant || (isMobile ? 'display6' : 'display5');

  const headerDesktopStyles = {
    display: 'flex',
    position: isWindowLargerThanHeaderHeight
      ? ('sticky' as const)
      : ('static' as const),
    top: isWindowLargerThanHeaderHeight ? '0' : 'auto',
    background: theme.palette.background.canvas,
    borderBottom: `1px solid ${
      isScrolled ? theme.palette.divider : 'transparent'
    }`,
    zIndex: '11', // External link icon arrow on Discover is 10
  };

  const headerMobileStyles = {
    ...headerDesktopStyles,
    justifyContent: 'center',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
  };

  let contentStyles: Record<string, unknown> = {
    display: 'flex',
    padding: `${theme.spacing(3)} 0`, // Side padding handled by parent Layout to align with body
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 0',
    height: '96px',
  };

  if (isMobile) {
    contentStyles = {
      ...contentStyles,
      padding: `${theme.spacing(2)} 0`, // Side padding handled by parent Layout to align with body
      height: '76px',
    };
  }

  const HEADER_PLUS_NAV_HEIGHT_BOUND_PX = 188;
  useEffect(() => {
    const checkHeight = () => {
      if (window.innerHeight > HEADER_PLUS_NAV_HEIGHT_BOUND_PX) {
        setIsWindowLargerThanHeaderHeight(true);
      } else {
        setIsWindowLargerThanHeaderHeight(false);
      }
    };

    // Check the height on component mount
    checkHeight();

    // Add a resize event listener to check height on window resize
    window.addEventListener('resize', checkHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkHeight);
    };
  }, []);

  return (
    <>
      <div ref={sentinelRef} className="sentinel"></div>
      <header
        style={isMobile ? headerMobileStyles : headerDesktopStyles}
        data-id="header-container"
      >
        {/* We set our own gutters in Layout parent */}
        <Container disableGutters sx={contentStyles} maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {hasBackButton && (
              <IconButton
                data-testid="back-button"
                sx={{
                  marginRight: theme.spacing(1),
                  position: 'relative',
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  gap: theme.spacing(1),
                  ':focus': {
                    outline: `2px solid ${theme.palette.primary.main}`,
                  },
                }}
                onClick={() => onBackButtonClick && onBackButtonClick()}
                aria-label={backButtonDescription}
                title={backButtonDescription}
              >
                <ArrowBackIcon
                  sx={{color: color || theme.palette.text.default}}
                />
              </IconButton>
            )}
            {loading ? (
              <div data-testid="loading-skeleton">
                <PillSkeleton
                  width={theme.spacing(17.5)}
                  height={theme.spacing(4)}
                />
              </div>
            ) : (
              <Typography
                sx={{
                  // TODO(ONEVERILY-17471): Import Poppins `500` weight font &
                  // replace `600` instances
                  fontWeight: headerTextVariant.startsWith('display')
                    ? 600
                    : undefined,
                  color,
                  overflowWrap: 'break-word',
                }}
                component="h1"
                variant={headerTextVariant}
                noWrap={false}
              >
                {headerText}
              </Typography>
            )}
            <Box
              sx={{
                marginLeft: 'auto',
              }}
            >
              {children}
            </Box>
          </Box>
        </Container>
      </header>
    </>
  );
};

export default Header;
