/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/userprofile/bff/api/v1/user_profile_service.proto" (package "verily_me.web.userprofile.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../../../../google/protobuf/timestamp";
/**
 * Request to retrieve participant's data to be displayed in User Profile.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.GetUserProfileRequest
 */
export interface GetUserProfileRequest {
}
/**
 * Request to retrieve a URL for participant to reset password.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.GetPasswordResetUrlRequest
 */
export interface GetPasswordResetUrlRequest {
}
/**
 * Represents participant's data to be displayed in User Profile.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.UserProfile
 */
export interface UserProfile {
    /**
     * Name associated with the participant.
     *
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.HumanName name = 1;
     */
    name?: HumanName;
    /**
     * Email address associated with the participant's account.
     *
     * @generated from protobuf field: string email_address = 2;
     */
    emailAddress: string;
    /**
     * List of privacy and legal agreements the participant has consented to.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.Agreement agreements = 3;
     */
    agreements: Agreement[];
    /**
     * List of research studies the participant is part of.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview research_studies = 4;
     */
    researchStudies: ResearchStudyOverview[];
    /**
     * ID Verification State
     *
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.VerificationState verification_state = 5;
     */
    verificationState: VerificationState;
}
/**
 * Represents the name of the participant.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.HumanName
 */
export interface HumanName {
    /**
     * Given names, including first name and middle name.
     * Only first name will be returned currently.
     *
     * @generated from protobuf field: repeated string given_names = 1;
     */
    givenNames: string[];
    /**
     * Family name.
     *
     * @generated from protobuf field: string family_name = 2;
     */
    familyName: string;
    /**
     * Initials of full name. Max two characters.
     *
     * @generated from protobuf field: string initials = 3;
     */
    initials: string;
}
/**
 * Represents the consented privacy and legal agreement.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.Agreement
 */
export interface Agreement {
    /**
     * Identifier of the agreement.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Display name of the agreement.
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * URL of the agreement.
     *
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * Indentifier of the consent that was agreed to.
     *
     * @generated from protobuf field: string consent_id = 4;
     */
    consentId: string;
}
/**
 * Contains summarized data on a research study.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview
 */
export interface ResearchStudyOverview {
    /**
     * Research study ID.
     *
     * @generated from protobuf field: string study_id = 1;
     */
    studyId: string;
    /**
     * Research subject ID.
     *
     * @generated from protobuf field: string subject_id = 2;
     */
    subjectId: string;
    /**
     * Localized study title.
     *
     * @generated from protobuf field: string display_name = 3;
     */
    displayName: string;
    /**
     * Localized display text representing the study_status.
     *
     * @generated from protobuf field: string study_status_text = 4;
     */
    studyStatusText: string;
    /**
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview.StudyStatus study_status = 5;
     */
    studyStatus: ResearchStudyOverview_StudyStatus;
}
/**
 * @generated from protobuf enum verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview.StudyStatus
 */
export enum ResearchStudyOverview_StudyStatus {
    /**
     * @generated from protobuf enum value: STUDY_STATUS_UNSPECIFIED = 0;
     */
    STUDY_STATUS_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: SIGNED = 1;
     */
    SIGNED = 1,
    /**
     * @generated from protobuf enum value: DECLINED = 2;
     */
    DECLINED = 2,
    /**
     * @generated from protobuf enum value: WITHDRAWN = 3;
     */
    WITHDRAWN = 3
}
/**
 * A wrapper message for a URL that resets password.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.PasswordResetUrl
 */
export interface PasswordResetUrl {
    /**
     * Password reset URL.
     *
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * Specifies the study and subject that details should be retrieved for.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.GetResearchStudyDetailsRequest
 */
export interface GetResearchStudyDetailsRequest {
    /**
     * Research study ID
     *
     * @generated from protobuf field: string study_id = 1;
     */
    studyId: string;
    /**
     * Research subject ID
     *
     * @generated from protobuf field: string subject_id = 2;
     */
    subjectId: string;
}
/**
 * Detailed information on a specific subject's participation in a research study,
 * suitable for displaying on the research studies page.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ResearchStudyDetails
 */
export interface ResearchStudyDetails {
    /**
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview overview = 1;
     */
    overview?: ResearchStudyOverview;
    /**
     * A list of consent documents related to this study.
     *
     * @generated from protobuf field: repeated verily_me.web.userprofile.bff.api.v1.ConsentDocument consent_documents = 2;
     */
    consentDocuments: ConsentDocument[];
    /**
     * Localized text for the header to be shown above the list of consent documents.
     *
     * @generated from protobuf field: string header_text = 3;
     */
    headerText: string;
    /**
     * Localized text content to be displayed directly after the list of consent documents.
     *
     * @generated from protobuf field: string notes_content = 4;
     */
    notesContent: string;
}
/**
 * Contains information on a specific consent document that the user would be
 * asked to sign as part of a research study.
 *
 * @generated from protobuf message verily_me.web.userprofile.bff.api.v1.ConsentDocument
 */
export interface ConsentDocument {
    /**
     * ID of the consent record.
     *
     * @generated from protobuf field: string record_id = 1;
     */
    recordId: string;
    /**
     * Display title of the document
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Localized string describing the number of versions the document has gone through.
     *
     * @generated from protobuf field: string versions_count_text = 3;
     */
    versionsCountText: string;
    /**
     * Localized string describing the time and state of the last change to the consent state, e.g. 'withdrawn last thursday'
     *
     * @generated from protobuf field: string last_updated_text = 4;
     */
    lastUpdatedText: string;
    /**
     * Timestamp for when document was last signed OR withdrawn.
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_updated = 5;
     */
    lastUpdated?: Timestamp;
    /**
     * @generated from protobuf field: verily_me.web.userprofile.bff.api.v1.ConsentDocument.ConsentType consent_type = 6;
     */
    consentType: ConsentDocument_ConsentType;
}
/**
 * @generated from protobuf enum verily_me.web.userprofile.bff.api.v1.ConsentDocument.ConsentType
 */
export enum ConsentDocument_ConsentType {
    /**
     * @generated from protobuf enum value: CONSENT_TYPE_UNSPECIFIED = 0;
     */
    CONSENT_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: PRIMARY = 1;
     */
    PRIMARY = 1,
    /**
     * @generated from protobuf enum value: SECONDARY = 2;
     */
    SECONDARY = 2
}
/**
 * @generated from protobuf enum verily_me.web.userprofile.bff.api.v1.VerificationState
 */
export enum VerificationState {
    /**
     * Default value, unused.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_UNKNOWN = 0;
     */
    VERIFICATION_STATE_UNKNOWN = 0,
    /**
     * Participant or Admin started verification process, but not yet complete.
     *
     * @generated from protobuf enum value: IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * Participant's identity was successfully verified.
     *
     * @generated from protobuf enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
    /**
     * Identity Verification failed by vendor decision.
     *
     * @generated from protobuf enum value: FAILURE = 3;
     */
    FAILURE = 3,
    /**
     * Verification cancelled by Admin action.
     *
     * @generated from protobuf enum value: CANCELLED = 4;
     */
    CANCELLED = 4,
    /**
     * Verification not completed by participant and declared abandoned.
     *
     * @generated from protobuf enum value: ABANDONED = 5;
     */
    ABANDONED = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUserProfileRequest$Type extends MessageType<GetUserProfileRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.GetUserProfileRequest", []);
    }
    create(value?: PartialMessage<GetUserProfileRequest>): GetUserProfileRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserProfileRequest): GetUserProfileRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetUserProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.GetUserProfileRequest
 */
export const GetUserProfileRequest = new GetUserProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordResetUrlRequest$Type extends MessageType<GetPasswordResetUrlRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.GetPasswordResetUrlRequest", []);
    }
    create(value?: PartialMessage<GetPasswordResetUrlRequest>): GetPasswordResetUrlRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordResetUrlRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordResetUrlRequest): GetPasswordResetUrlRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPasswordResetUrlRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.GetPasswordResetUrlRequest
 */
export const GetPasswordResetUrlRequest = new GetPasswordResetUrlRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserProfile$Type extends MessageType<UserProfile> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.UserProfile", [
            { no: 1, name: "name", kind: "message", T: () => HumanName },
            { no: 2, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "agreements", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Agreement },
            { no: 4, name: "research_studies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ResearchStudyOverview },
            { no: 5, name: "verification_state", kind: "enum", T: () => ["verily_me.web.userprofile.bff.api.v1.VerificationState", VerificationState] }
        ]);
    }
    create(value?: PartialMessage<UserProfile>): UserProfile {
        const message = { emailAddress: "", agreements: [], researchStudies: [], verificationState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserProfile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserProfile): UserProfile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.userprofile.bff.api.v1.HumanName name */ 1:
                    message.name = HumanName.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* string email_address */ 2:
                    message.emailAddress = reader.string();
                    break;
                case /* repeated verily_me.web.userprofile.bff.api.v1.Agreement agreements */ 3:
                    message.agreements.push(Agreement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview research_studies */ 4:
                    message.researchStudies.push(ResearchStudyOverview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* verily_me.web.userprofile.bff.api.v1.VerificationState verification_state */ 5:
                    message.verificationState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserProfile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.userprofile.bff.api.v1.HumanName name = 1; */
        if (message.name)
            HumanName.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string email_address = 2; */
        if (message.emailAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.emailAddress);
        /* repeated verily_me.web.userprofile.bff.api.v1.Agreement agreements = 3; */
        for (let i = 0; i < message.agreements.length; i++)
            Agreement.internalBinaryWrite(message.agreements[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview research_studies = 4; */
        for (let i = 0; i < message.researchStudies.length; i++)
            ResearchStudyOverview.internalBinaryWrite(message.researchStudies[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* verily_me.web.userprofile.bff.api.v1.VerificationState verification_state = 5; */
        if (message.verificationState !== 0)
            writer.tag(5, WireType.Varint).int32(message.verificationState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.UserProfile
 */
export const UserProfile = new UserProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HumanName$Type extends MessageType<HumanName> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.HumanName", [
            { no: 1, name: "given_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "family_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "initials", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HumanName>): HumanName {
        const message = { givenNames: [], familyName: "", initials: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HumanName>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HumanName): HumanName {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string given_names */ 1:
                    message.givenNames.push(reader.string());
                    break;
                case /* string family_name */ 2:
                    message.familyName = reader.string();
                    break;
                case /* string initials */ 3:
                    message.initials = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HumanName, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string given_names = 1; */
        for (let i = 0; i < message.givenNames.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.givenNames[i]);
        /* string family_name = 2; */
        if (message.familyName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.familyName);
        /* string initials = 3; */
        if (message.initials !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.initials);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.HumanName
 */
export const HumanName = new HumanName$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Agreement$Type extends MessageType<Agreement> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.Agreement", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Agreement>): Agreement {
        const message = { id: "", displayName: "", url: "", consentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Agreement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Agreement): Agreement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* string consent_id */ 4:
                    message.consentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Agreement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* string consent_id = 4; */
        if (message.consentId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.consentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.Agreement
 */
export const Agreement = new Agreement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResearchStudyOverview$Type extends MessageType<ResearchStudyOverview> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview", [
            { no: 1, name: "study_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subject_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "study_status_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "study_status", kind: "enum", T: () => ["verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview.StudyStatus", ResearchStudyOverview_StudyStatus] }
        ]);
    }
    create(value?: PartialMessage<ResearchStudyOverview>): ResearchStudyOverview {
        const message = { studyId: "", subjectId: "", displayName: "", studyStatusText: "", studyStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResearchStudyOverview>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResearchStudyOverview): ResearchStudyOverview {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string study_id */ 1:
                    message.studyId = reader.string();
                    break;
                case /* string subject_id */ 2:
                    message.subjectId = reader.string();
                    break;
                case /* string display_name */ 3:
                    message.displayName = reader.string();
                    break;
                case /* string study_status_text */ 4:
                    message.studyStatusText = reader.string();
                    break;
                case /* verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview.StudyStatus study_status */ 5:
                    message.studyStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResearchStudyOverview, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string study_id = 1; */
        if (message.studyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.studyId);
        /* string subject_id = 2; */
        if (message.subjectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subjectId);
        /* string display_name = 3; */
        if (message.displayName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.displayName);
        /* string study_status_text = 4; */
        if (message.studyStatusText !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.studyStatusText);
        /* verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview.StudyStatus study_status = 5; */
        if (message.studyStatus !== 0)
            writer.tag(5, WireType.Varint).int32(message.studyStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview
 */
export const ResearchStudyOverview = new ResearchStudyOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PasswordResetUrl$Type extends MessageType<PasswordResetUrl> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.PasswordResetUrl", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PasswordResetUrl>): PasswordResetUrl {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PasswordResetUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PasswordResetUrl): PasswordResetUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PasswordResetUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.PasswordResetUrl
 */
export const PasswordResetUrl = new PasswordResetUrl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResearchStudyDetailsRequest$Type extends MessageType<GetResearchStudyDetailsRequest> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.GetResearchStudyDetailsRequest", [
            { no: 1, name: "study_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subject_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetResearchStudyDetailsRequest>): GetResearchStudyDetailsRequest {
        const message = { studyId: "", subjectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetResearchStudyDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResearchStudyDetailsRequest): GetResearchStudyDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string study_id */ 1:
                    message.studyId = reader.string();
                    break;
                case /* string subject_id */ 2:
                    message.subjectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResearchStudyDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string study_id = 1; */
        if (message.studyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.studyId);
        /* string subject_id = 2; */
        if (message.subjectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subjectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.GetResearchStudyDetailsRequest
 */
export const GetResearchStudyDetailsRequest = new GetResearchStudyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResearchStudyDetails$Type extends MessageType<ResearchStudyDetails> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ResearchStudyDetails", [
            { no: 1, name: "overview", kind: "message", T: () => ResearchStudyOverview },
            { no: 2, name: "consent_documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ConsentDocument },
            { no: 3, name: "header_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes_content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResearchStudyDetails>): ResearchStudyDetails {
        const message = { consentDocuments: [], headerText: "", notesContent: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResearchStudyDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResearchStudyDetails): ResearchStudyDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview overview */ 1:
                    message.overview = ResearchStudyOverview.internalBinaryRead(reader, reader.uint32(), options, message.overview);
                    break;
                case /* repeated verily_me.web.userprofile.bff.api.v1.ConsentDocument consent_documents */ 2:
                    message.consentDocuments.push(ConsentDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string header_text */ 3:
                    message.headerText = reader.string();
                    break;
                case /* string notes_content */ 4:
                    message.notesContent = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResearchStudyDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.userprofile.bff.api.v1.ResearchStudyOverview overview = 1; */
        if (message.overview)
            ResearchStudyOverview.internalBinaryWrite(message.overview, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated verily_me.web.userprofile.bff.api.v1.ConsentDocument consent_documents = 2; */
        for (let i = 0; i < message.consentDocuments.length; i++)
            ConsentDocument.internalBinaryWrite(message.consentDocuments[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string header_text = 3; */
        if (message.headerText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.headerText);
        /* string notes_content = 4; */
        if (message.notesContent !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notesContent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ResearchStudyDetails
 */
export const ResearchStudyDetails = new ResearchStudyDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentDocument$Type extends MessageType<ConsentDocument> {
    constructor() {
        super("verily_me.web.userprofile.bff.api.v1.ConsentDocument", [
            { no: 1, name: "record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "versions_count_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "last_updated_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_updated", kind: "message", T: () => Timestamp },
            { no: 6, name: "consent_type", kind: "enum", T: () => ["verily_me.web.userprofile.bff.api.v1.ConsentDocument.ConsentType", ConsentDocument_ConsentType] }
        ]);
    }
    create(value?: PartialMessage<ConsentDocument>): ConsentDocument {
        const message = { recordId: "", title: "", versionsCountText: "", lastUpdatedText: "", consentType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentDocument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentDocument): ConsentDocument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string record_id */ 1:
                    message.recordId = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string versions_count_text */ 3:
                    message.versionsCountText = reader.string();
                    break;
                case /* string last_updated_text */ 4:
                    message.lastUpdatedText = reader.string();
                    break;
                case /* google.protobuf.Timestamp last_updated */ 5:
                    message.lastUpdated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastUpdated);
                    break;
                case /* verily_me.web.userprofile.bff.api.v1.ConsentDocument.ConsentType consent_type */ 6:
                    message.consentType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentDocument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string record_id = 1; */
        if (message.recordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.recordId);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string versions_count_text = 3; */
        if (message.versionsCountText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.versionsCountText);
        /* string last_updated_text = 4; */
        if (message.lastUpdatedText !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastUpdatedText);
        /* google.protobuf.Timestamp last_updated = 5; */
        if (message.lastUpdated)
            Timestamp.internalBinaryWrite(message.lastUpdated, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* verily_me.web.userprofile.bff.api.v1.ConsentDocument.ConsentType consent_type = 6; */
        if (message.consentType !== 0)
            writer.tag(6, WireType.Varint).int32(message.consentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.userprofile.bff.api.v1.ConsentDocument
 */
export const ConsentDocument = new ConsentDocument$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.userprofile.bff.api.v1.UserProfileService
 */
export const UserProfileService = new ServiceType("verily_me.web.userprofile.bff.api.v1.UserProfileService", [
    { name: "GetUserProfile", options: {}, I: GetUserProfileRequest, O: UserProfile },
    { name: "GetPasswordResetUrl", options: {}, I: GetPasswordResetUrlRequest, O: PasswordResetUrl },
    { name: "GetResearchStudyDetails", options: {}, I: GetResearchStudyDetailsRequest, O: ResearchStudyDetails }
]);
