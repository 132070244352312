import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {
  BatchUpdateNotificationUserPreferencesRequest,
  BatchUpdateNotificationUserPreferencesResponse,
  BatchUpdateNotificationUserPreferencesUnauthenticatedRequest,
  BatchUpdateNotificationUserPreferencesUnauthenticatedResponse,
  ListNotificationUserPreferencesRequest,
  ListNotificationUserPreferencesResponse,
  ListNotificationUserPreferencesUnauthenticatedRequest,
  NotificationUserPreference,
  UnsubscribeFromEmailRequest,
} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service';
import {NotificationUserPreferencesServiceClient} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class NotificationUserPreferencesService {
  private notificationUserPreferencesServiceClient;
  private unAuthNotificationUserPreferencesServiceClient;

  async getClient(): Promise<NotificationUserPreferencesServiceClient> {
    if (!this.notificationUserPreferencesServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.notificationUserPreferencesServiceClient =
        new NotificationUserPreferencesServiceClient(transport);
    }

    return this.notificationUserPreferencesServiceClient;
  }

  async getUnauthenticatedClient(): Promise<NotificationUserPreferencesServiceClient> {
    if (!this.unAuthNotificationUserPreferencesServiceClient) {
      const interceptors: RpcInterceptor[] = [];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.unAuthNotificationUserPreferencesServiceClient =
        new NotificationUserPreferencesServiceClient(transport);
    }

    return this.unAuthNotificationUserPreferencesServiceClient;
  }

  async listNotificationUserPreferences(
    listByCategory?: boolean
  ): Promise<ListNotificationUserPreferencesResponse> {
    const request: ListNotificationUserPreferencesRequest = {
      listByCategory,
    };

    const notificationPreferencesClient = await this.getClient();

    return (
      await notificationPreferencesClient.listNotificationUserPreferences(
        request
      )
    ).response;
  }

  async batchUpdateNotificationUserPreferences(
    ...preferences: NotificationUserPreference[]
  ): Promise<BatchUpdateNotificationUserPreferencesResponse> {
    const request: BatchUpdateNotificationUserPreferencesRequest = {
      requests: preferences.map(preference => ({preference})),
    };

    const noitificationPreferencesClient = await this.getClient();

    return (
      await noitificationPreferencesClient.batchUpdateNotificationUserPreferences(
        request
      )
    ).response;
  }

  async listNotificationUserPreferencesUnauthenticated(
    communicationIdentifier: string,
    unsubscribeHash: string
  ): Promise<ListNotificationUserPreferencesResponse> {
    const request: ListNotificationUserPreferencesUnauthenticatedRequest = {
      communicationIdentifier,
      unsubscribeHash,
    };

    const client = await this.getUnauthenticatedClient();

    return (
      await client.listNotificationUserPreferencesUnauthenticated(request)
    ).response;
  }

  async unsubscribeFromEmail(
    communicationIdentifier: string,
    unsubscribeHash: string
  ): Promise<void> {
    const request: UnsubscribeFromEmailRequest = {
      communicationIdentifier,
      unsubscribeHash,
    };
    await (await this.getUnauthenticatedClient()).unsubscribeFromEmail(request);
  }

  async batchUpdateNotificationUserPreferencesUnauthenticated(
    communicationIdentifier: string,
    unsubscribeHash: string,
    ...preferences: NotificationUserPreference[]
  ): Promise<BatchUpdateNotificationUserPreferencesUnauthenticatedResponse> {
    const request: BatchUpdateNotificationUserPreferencesUnauthenticatedRequest =
      {
        communicationIdentifier,
        unsubscribeHash,
        requests: preferences.map(preference => ({preference})),
      };

    const client = await this.getUnauthenticatedClient();

    return (
      await client.batchUpdateNotificationUserPreferencesUnauthenticated(
        request
      )
    ).response;
  }
}

export default new NotificationUserPreferencesService();
