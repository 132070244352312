import {LogErrorRequest_Severity} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/logger_service';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import logError from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/logError';

export default (error: Error) =>
  logError(
    error,
    'verily-me-web-userprofile-mfe',
    isAuthenticationError(error)
      ? LogErrorRequest_Severity.WARNING
      : LogErrorRequest_Severity.ERROR
  );
