import styles from './SrText.styles';

type SrTextProps = {
  text: string;
};

/**
 * SrText Component
 *
 * A React component for screen-reader-only text. It renders the provided text
 * in a <span> element with styles that make it accessible for screen readers
 * while remaining hidden visually.
 *
 * @component
 * @param {string} text - The text to be rendered for screen readers.
 *
 * @example
 * // Example usage of SrText component
 * <SrText text="This is screen-reader-only text" />
 */
const SrText = ({text}: SrTextProps) => {
  return (
    <span style={styles.srOnly} tabIndex={-1}>
      {text}
    </span>
  );
};

export default SrText;
