import {Box, Stack, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Card,
  CircularProgress,
  TextSnippetIcon,
} from '@verily-src/react-design-system';
import {ResearchStudyDetails} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {navigateToUrl} from 'single-spa';
import UserProfileService from '../../services/UserProfileService';
import logError from '../../utils/logError';

export default function ResearchStudy() {
  const {t} = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const skeletonsEnabled = config.getBoolean('FEATURE_SKELETONS_ENABLED');

  const [studyDetails, setStudyDetails] = useState<
    ResearchStudyDetails | undefined
  >();
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const studyId = searchParams.get('studyId');
  const subjectId = searchParams.get('subjectId');

  const loadResearchStudy = async () => {
    try {
      const details = await UserProfileService.getResearchStudyDetails(
        studyId,
        subjectId
      );
      setStudyDetails(details);
    } catch (err) {
      logError(err);
      setLoadingError(true);
    }
  };

  useEffect(() => {
    if (studyId && subjectId) {
      loadResearchStudy();
    }
  }, [studyId, subjectId]);

  useEffect(() => {
    if (studyDetails?.overview) {
      document.title = `Verily Me | ${t('profile-header')} | ${
        studyDetails.overview?.displayName
      }`;
    }
  }, [studyDetails]);

  if (loadingError) {
    return (
      <ErrorView
        title={t('something-went-wrong')}
        subtitle={t('were-having-an-issue')}
        refreshText={t('refresh')}
      />
    );
  }

  if (!studyDetails && !skeletonsEnabled) {
    return (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  return (
    <Layout
      header={
        <Header
          headerText={studyDetails?.overview?.displayName}
          headerVariant="body1"
          color={theme.palette.text.default}
          hasBackButton
          onBackButtonClick={() => navigateToUrl('/me/profile')}
          backButtonDescription={t('back-button-description')}
          loading={!studyDetails}
        />
      }
    >
      <section>
        <div
          style={{
            paddingTop: theme.spacing(4),
          }}
        >
          {!studyDetails ? (
            <PillSkeleton
              width={theme.spacing(17.5)}
              height={theme.spacing(4)}
            />
          ) : (
            <Typography component={'h2'} variant="h6">
              {studyDetails.headerText}
            </Typography>
          )}
          <Box
            sx={{paddingY: theme.spacing(2)}}
            data-testid="consents-container"
          >
            <Stack spacing={1}>
              {!studyDetails && (
                <>
                  <PillSkeleton width={'95%'} height={theme.spacing(2)} />
                  <PillSkeleton width={'65%'} height={theme.spacing(2)} />
                </>
              )}
              {studyDetails?.consentDocuments.map(consent => (
                <Card
                  key={consent.recordId}
                  title={consent.title}
                  subtitle={consent.lastUpdatedText}
                  useUnlaunchedComponent
                  pendo-vme-view-consent-id={consent.recordId}
                  pendo-vme-view-consent-title={consent.title}
                  IconComponent={
                    <TextSnippetIcon sx={{color: theme.palette.primary.main}} />
                  }
                  action={{
                    onClick: () => {
                      navigateToUrl(
                        `/me/consent/review?consentRecordId=${consent.recordId}`
                      );
                    },
                    text: consent.versionsCountText,
                    hasIcon: true,
                  }}
                />
              ))}
            </Stack>
          </Box>
          <Typography>{studyDetails?.notesContent}</Typography>
        </div>
      </section>
    </Layout>
  );
}
