import {Snackbar} from '@verily-src/react-design-system';

type ErrorToastProps = {
  open: boolean;
  onClose: () => void;
  errorMessage?: string;
  userFriendlyText: string;
  showErrorMessages?: boolean;
};

const ErrorToast = ({
  open,
  onClose,
  userFriendlyText,
  errorMessage,
  showErrorMessages,
}: ErrorToastProps) => {
  return (
    <div data-testid="error-toast">
      <Snackbar
        withIcon={true}
        color={'error'}
        open={open}
        onClose={onClose}
        text={
          showErrorMessages && errorMessage ? errorMessage : userFriendlyText
        }
      />
    </div>
  );
};

export default ErrorToast;
