import Pseudo from 'i18next-pseudo';

// PseudoLocalePlugin is basically a wrapper around the `i81next-pseudo` package that allows
// using a different locale name ('xa' by default) for the pseudotranslations.
export class PseudoLocalePlugin {
  name: 'verilyPseudo' = 'verilyPseudo';
  type: 'postProcessor' = 'postProcessor';
  localeName: string;
  pseudo: Pseudo;

  constructor({
    localeName = 'xa',
    languageToPseudo = 'en',
    letterMultiplier = 2,
    repeatedLetters = undefined,
    uglifedLetterObject = undefined,
    wrapped = false,
    enabled = true,
  } = {}) {
    this.localeName = localeName;
    this.pseudo = new Pseudo({
      languageToPseudo,
      letterMultiplier,
      repeatedLetters,
      uglifedLetterObject,
      wrapped,
      enabled,
    });
  }

  process(value, key, options, translator) {
    if (translator.language !== this.localeName) {
      return value;
    }
    // en-US is the fallback for all locales so `value` should be the en-US translation here.
    const pseudoTranslation = this.pseudo.process(value, key, options, {
      language: this.pseudo.options.languageToPseudo,
    });
    return pseudoTranslation;
  }
}
