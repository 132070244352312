/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/userprofile/bff/api/v1/user_profile_service.proto" (package "verily_me.web.userprofile.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserProfileService } from "./user_profile_service";
import type { ResearchStudyDetails } from "./user_profile_service";
import type { GetResearchStudyDetailsRequest } from "./user_profile_service";
import type { PasswordResetUrl } from "./user_profile_service";
import type { GetPasswordResetUrlRequest } from "./user_profile_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { UserProfile } from "./user_profile_service";
import type { GetUserProfileRequest } from "./user_profile_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * //////////////////////// Potential Errors //////////////////////////
 * GetUserProfile
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: consent records cannot be listed
 * Error: codes.Internal
 * Scenario: cannot convert consent records to agreements
 * Error: codes.Internal
 * GetPasswordResetUrl
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: ciam server returns error
 * Error: Same error as ciam server returns
 * ////////////////////////////////////////////////////////////////////
 * User Profile Service for retrieving participant's information.
 *
 * @generated from protobuf service verily_me.web.userprofile.bff.api.v1.UserProfileService
 */
export interface IUserProfileServiceClient {
    /**
     * Returns participant's data to be displayed in User Profile.
     *
     * @generated from protobuf rpc: GetUserProfile(verily_me.web.userprofile.bff.api.v1.GetUserProfileRequest) returns (verily_me.web.userprofile.bff.api.v1.UserProfile);
     */
    getUserProfile(input: GetUserProfileRequest, options?: RpcOptions): UnaryCall<GetUserProfileRequest, UserProfile>;
    /**
     * Returns a URL for participant to reset password.
     *
     * @generated from protobuf rpc: GetPasswordResetUrl(verily_me.web.userprofile.bff.api.v1.GetPasswordResetUrlRequest) returns (verily_me.web.userprofile.bff.api.v1.PasswordResetUrl);
     */
    getPasswordResetUrl(input: GetPasswordResetUrlRequest, options?: RpcOptions): UnaryCall<GetPasswordResetUrlRequest, PasswordResetUrl>;
    /**
     * Returns detailed information on a given research study.
     *
     * @generated from protobuf rpc: GetResearchStudyDetails(verily_me.web.userprofile.bff.api.v1.GetResearchStudyDetailsRequest) returns (verily_me.web.userprofile.bff.api.v1.ResearchStudyDetails);
     */
    getResearchStudyDetails(input: GetResearchStudyDetailsRequest, options?: RpcOptions): UnaryCall<GetResearchStudyDetailsRequest, ResearchStudyDetails>;
}
/**
 * //////////////////////// Potential Errors //////////////////////////
 * GetUserProfile
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: consent records cannot be listed
 * Error: codes.Internal
 * Scenario: cannot convert consent records to agreements
 * Error: codes.Internal
 * GetPasswordResetUrl
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: ciam server returns error
 * Error: Same error as ciam server returns
 * ////////////////////////////////////////////////////////////////////
 * User Profile Service for retrieving participant's information.
 *
 * @generated from protobuf service verily_me.web.userprofile.bff.api.v1.UserProfileService
 */
export class UserProfileServiceClient implements IUserProfileServiceClient, ServiceInfo {
    typeName = UserProfileService.typeName;
    methods = UserProfileService.methods;
    options = UserProfileService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Returns participant's data to be displayed in User Profile.
     *
     * @generated from protobuf rpc: GetUserProfile(verily_me.web.userprofile.bff.api.v1.GetUserProfileRequest) returns (verily_me.web.userprofile.bff.api.v1.UserProfile);
     */
    getUserProfile(input: GetUserProfileRequest, options?: RpcOptions): UnaryCall<GetUserProfileRequest, UserProfile> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserProfileRequest, UserProfile>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns a URL for participant to reset password.
     *
     * @generated from protobuf rpc: GetPasswordResetUrl(verily_me.web.userprofile.bff.api.v1.GetPasswordResetUrlRequest) returns (verily_me.web.userprofile.bff.api.v1.PasswordResetUrl);
     */
    getPasswordResetUrl(input: GetPasswordResetUrlRequest, options?: RpcOptions): UnaryCall<GetPasswordResetUrlRequest, PasswordResetUrl> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPasswordResetUrlRequest, PasswordResetUrl>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns detailed information on a given research study.
     *
     * @generated from protobuf rpc: GetResearchStudyDetails(verily_me.web.userprofile.bff.api.v1.GetResearchStudyDetailsRequest) returns (verily_me.web.userprofile.bff.api.v1.ResearchStudyDetails);
     */
    getResearchStudyDetails(input: GetResearchStudyDetailsRequest, options?: RpcOptions): UnaryCall<GetResearchStudyDetailsRequest, ResearchStudyDetails> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetResearchStudyDetailsRequest, ResearchStudyDetails>("unary", this._transport, method, opt, input);
    }
}
